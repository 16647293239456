import React, { useEffect } from 'react';
import Router from 'next/router';

function IndexPage() {

  useEffect(() => {
    Router.push('/preview');
  });

  return (
    <>
    </>
  );
}

export default IndexPage;
